import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

const baseURL = getEnvVariable('VUE_APP_AUTH_API_URL');

const beforeRequestInterceptor = ({ requestConfig, action, resource }) => {
    if (action === 'get' && resource === 'profile') {
        return {
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                'X-Quext-Contact-Id': store.getters['auth/currentProfile']?.contactId,
            },
        };
    }

    return requestConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, { beforeRequestInterceptor });

export default new DataProvider(httpAdapter);

<template>
    <div>
        <icon
            name="burger"
            class="w-6 h-6 block text-gray-800 cursor-pointer"
            @click="isOpen = !isOpen"
        />

        <SlideOver
            v-if="isOpen"
            @close="isOpen = false"
        >
            <ContentArea content-class="">
                <template v-slot:header>
                    <div class="flex flex-col bg-gray-800 p-6">
                        <icon
                            name="user-circle"
                            class="w-12 h-12 text-gray-600 mb-4"
                        ></icon>
                        <div class="font-ffdin text-sm uppercase mb-3">{{ $t('app.title') }}</div>
                        <div class="font-sofia text-base mb-1">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
                        <div class="font-ffdin text-xs">{{ currentUser.email }}</div>
                    </div>
                </template>

                <template v-slot:default>
                    <div class="h-full pb-6 flex flex-col justify-between">
                        <div class="mt-2">
                            <router-link
                                v-for="(route, index) in routes"
                                :key="index"
                                :to="{ name: route.name }"
                                class="flex items-center h-10 px-8 font-ffdin text-gray-200 font-light text-sm py-2 hover:text-white"
                                @click="isOpen = false"
                            >
                                {{ $t(route.title) }}
                            </router-link>
                        </div>
                        <div
                            v-if="currentCommunity"
                            class="text-gray-200 px-8 mt-5"
                        >
                            <p class="uppercase font-normal text-md tracking-wide3 pb-3 font-ffdin communityName">
                                {{ currentCommunity.name }}
                            </p>
                            <p class="address text-xs font-medium">
                                {{ currentCommunity.address.addressLine1 }}
                                {{ currentCommunity.address.zip }} {{ currentCommunity.address.city }},
                                {{ currentCommunity.address.state }}
                            </p>
                            <p class="text-xs font-medium">
                                <span class="capitalize">{{ $t('common.main') }}</span
                                >: {{ currentCommunity.phone }}
                            </p>
                        </div>
                    </div>
                </template>
            </ContentArea>
        </SlideOver>
    </div>
</template>

<script>
import SlideOver from '@/components/root/SlideOver';
import Icon from '@/components/ui/Icon';
import ContentArea from '@/components/ui/ContentArea';
import AuthMixin from '@/mixins/AuthMixin';

export default {
    components: { ContentArea, SlideOver, Icon },

    mixins: [AuthMixin],

    props: {
        routes: {
            type: Array,
            required: true,
        },
    },

    data: () => {
        return {
            isOpen: false,
        };
    },
};
</script>

import { createNamespacedHelpers } from 'vuex';
import { groupBy, maxBy } from 'lodash-es';

const { mapActions, mapGetters } = createNamespacedHelpers('auth');

export default {
    computed: {
        ...mapGetters(['authenticated', 'profiles', 'currentProfile', 'appsPermissions', 'currentUser', 'currentCommunity']),

        /**
         * @returns {*} same structure as `profiles` but with last profiles for each occupancy
         */
        profilesByLastOccupancy() {
            return this.profiles.map(({ communityName, profiles }) => {
                return {
                    communityName,
                    profiles: Object.values(groupBy(profiles, 'occupancyId')).map(values => maxBy(values, 'phaseIndex')),
                };
            });
        },
    },

    methods: {
        ...mapActions([
            'signIn',
            'signOut',
            'setProfiles',
            'setCurrentProfile',
            'setAppPermissions',
            'clearAppPermissions',
            'setCurrentUser',
            'setCurrentCommunity',
        ]),
    },
};

<template>
    <div
        v-if="dialog"
        class="fixed backdrop z-50 top-0 left-0 w-screen h-screen p-8 flex justify-center items-center"
    >
        <div
            class="dialog"
            :class="dialogClass"
        >
            <icon
                name="close"
                class="dialog__close"
                @click="dialog.resolve(false)"
            />
            <div
                v-if="dialog.title"
                class="dialog__title"
            >
                {{ dialog.title }}
            </div>
            <div
                v-if="dialog.text"
                class="dialog__text"
                v-html="dialog.text"
            ></div>
            <div class="dialog__buttons">
                <button
                    type="button"
                    class="dialog__button btn"
                    @click="dialog.resolve(false)"
                >
                    {{ dialog.cancelCaption || $t('app.cancel_action') }}
                </button>
                <button
                    type="button"
                    class="dialog__button btn"
                    :class="confirmBtnClass"
                    @click="dialog.resolve(true)"
                >
                    {{ dialog.confirmCaption || $t('app.ok_action') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    computed: {
        ...mapGetters({ dialog: 'confirm/topDialog' }),

        confirmBtnClass() {
            return this.dialog.type === 'warning' ? 'btn-danger' : 'btn-primary';
        },

        dialogClass() {
            return this.dialog.type === 'warning' ? 'dialog--type-warning' : 'dialog--type-success';
        },
    },
};
</script>

<style>
.dialog {
    @apply bg-white p-8 pt-10 rounded-lg relative;
    width: 25rem;
}

.dialog__title {
    @apply font-ffdin text-base font-bold mb-4;
}

.dialog__close {
    @apply absolute top-2 right-2 w-6 h-6 cursor-pointer;
}

.dialog__text {
    @apply font-ffdin text-base font-medium;
}

.dialog__button {
    @apply flex-1;
}

.dialog__buttons {
    @apply flex mt-6;
}
</style>

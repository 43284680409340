<template>
    <div>
        <div
            v-if="isOpen"
            :class="listClassName"
            class="fixed z-40"
        >
            <div
                v-for="(route, index) in routes"
                :key="index"
                class="text-right mb-4"
            >
                <router-link
                    :to="{ name: route.name }"
                    class="px-6 py-2 rounded bg-purple-700 hover:bg-purple-800 text-gray-200 shadow"
                    @click="close"
                >
                    {{ $t(route.title) }}
                </router-link>
            </div>
        </div>
        <button
            class="btn btn-action w-12 md:w-16 h-12 px-0"
            @click="toggle"
        >
            <icon
                :name="iconName"
                class="w-8 h-8"
            ></icon>
        </button>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },
    props: {
        routes: {
            type: Array,
            required: true,
        },

        listClassName: {
            type: String,
            default: 'bottom-16 right-6',
        },
    },

    data: () => {
        return {
            isOpen: false,
        };
    },

    computed: {
        iconName() {
            return this.isOpen ? 'minus' : 'plus';
        },
    },

    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },

        close() {
            this.isOpen = false;
        },
    },
};
</script>

export default {
    create_package_instance: 'document/document-package-instance',
    update_package_instance: 'document/document-package-instance/{document_package_instance_id}/{business_unit_id}/{customer_id}',
    document_instance: {
        getOne: ['GET', 'document/document-package-instance/{document_package_instance_id}/{business_unit_id}/{customer_id}'],
    },
    occupancy: {
        getOne: ['GET', 'occupancy/{occupancy_id}'],
    },
    pet: {
        getOneByPartyId: ['GET', 'pet/party/{partyId}'],
    },
    occuppancyByBusiness: {
        getOne: ['GET', 'occupancy/business/{business_id}'],
    },
    application: {
        create: ['POST', 'application/application'],
        getOne: ['GET', 'application/application/by-contact/{contact_id}'],
        patch: ['PATCH', 'application/application/{application_id}'],
    },
    household: {
        getOne: ['GET', 'application/household/by-occupancy/{occupancy_id}'],
    },
    identity_verification: 'application/identity-verification',
    warning: 'application/warning',
    link_warning_identity_verification: 'application/identity-verification/create-with-warning',
    link_warning_background_screening: 'application/background-screening/create-with-warning',
    background_screening: 'application/background-screening',
    notes: 'application/notes',
    link_notes_background_screening: 'application/background-screening/create-with-note',
};

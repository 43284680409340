<template>
    <div class="w-full max-w-md p-10">
        <div class="flex flex-col items-center">
            <div v-show="!codeSent">
                <div class="font-ffdin font-bold text-lg uppercase text-center mb-6">
                    {{ $t('auth.forgot.step1_title') }}
                </div>
                <div class="text-sm mb-6 text-center">
                    {{ $t('auth.forgot.step1_message') }}
                </div>
                <Form
                    ref="codeForm"
                    :submit="sendCode"
                >
                    <TextField
                        name="username"
                        :label="$t('auth.username')"
                        :validate="required"
                        :disabled="loading"
                    />
                    <div class="form-field">
                        <button
                            type="submit"
                            class="btn btn-primary w-full"
                            :disabled="loading"
                        >
                            {{ $t('auth.forgot.reset_password_action') }}
                        </button>
                    </div>
                </Form>
            </div>
            <div v-show="codeSent">
                <div class="font-ffdin font-bold text-lg uppercase text-center mb-6">{{ $t('auth.forgot.step2_title') }}</div>
                <p class="text-sm mb-6 text-center">
                    {{ $t('auth.forgot.step2_message') }}
                </p>
                <Form
                    ref="passwordForm"
                    :submit="submit"
                >
                    <TextField
                        name="code"
                        label="code"
                        :validate="required"
                        :disabled="loading"
                    />
                    <TextField
                        type="password"
                        name="newPassword"
                        :label="$t('auth.new_password')"
                        :validate="required"
                        :disabled="loading"
                    />
                    <div class="form-field">
                        <button
                            type="submit"
                            class="btn btn-primary w-full"
                            :disabled="loading"
                        >
                            {{ $t('auth.forgot.reset_password_action') }}
                        </button>
                    </div>
                </Form>
            </div>
            <div class="mt-6">
                <button
                    type="button"
                    class="link"
                    @click="backToSignIn"
                >
                    {{ $t('auth.sign_in_link') }}
                </button>
            </div>
        </div>
        <loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import { useI18n } from 'vue-i18n';
import { AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL, AuthState } from '@aws-amplify/ui-components';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import Loader from '@/components/ui/Loader';
import { dispatchAuthStateChangeEvent, dispatchToastHubEvent } from './helpers';

export default {
    name: 'ForgotPassword',
    components: {
        Form,
        TextField,
        Loader,
    },

    mixins: [ValidatorMixin],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            codeSent: false,
            loading: false,
            username: undefined,
        };
    },

    mounted() {
        Hub.listen(UI_AUTH_CHANNEL, ({ payload: { event, data } }) => {
            if (event === AUTH_STATE_CHANGE_EVENT) {
                this.codeSent = data?.codeSent || false;
                if (data?.username) {
                    this.username = data.username;
                }
            }
        });
    },

    methods: {
        closeToast() {
            const toast = document.querySelector('amplify-authenticator').shadowRoot.querySelector('amplify-toast');

            if (toast) {
                toast.shadowRoot.querySelector('.toast-close').click();
            }
        },

        async sendCode({ username }) {
            try {
                this.loading = true;
                this.closeToast();
                this.username = username;
                await Auth.forgotPassword(username);
                this.codeSent = true;
            } catch (error) {
                dispatchToastHubEvent({
                    message: error.code === 'UserNotFoundException' ? this.t('auth.forgot.user_not_found_error') : error.message,
                });
            } finally {
                this.loading = false;
            }
        },

        async submit({ code, newPassword }) {
            try {
                this.loading = true;
                await Auth.forgotPasswordSubmit(this.username, code, newPassword);
                dispatchAuthStateChangeEvent(AuthState.SignIn);
                dispatchToastHubEvent({ message: this.t('auth.forgot.success_message') });

                setTimeout(() => {
                    this.$refs.codeForm.$refs.form.finalForm.restart();
                    this.$refs.passwordForm.$refs.form.finalForm.restart();
                });
            } catch (error) {
                dispatchToastHubEvent(error);
            } finally {
                this.loading = false;
            }
        },

        backToSignIn() {
            dispatchAuthStateChangeEvent(AuthState.SignIn);
        },
    },
};
</script>

<template>
    <div class="w-full max-w-md p-10">
        <div class="flex flex-col items-center">
            <div class="font-ffdin font-bold text-lg uppercase text-center mb-6">
                {{ $t('auth.require_new_password.title') }}
            </div>
            <Form
                ref="form"
                :submit="completeNewPassword"
            >
                <TextField
                    name="newPassword"
                    :label="$t('auth.new_password')"
                    type="password"
                    :validate="required"
                />
                <div class="form-field">
                    <button
                        type="submit"
                        class="btn btn-primary w-full"
                        :disabled="loading"
                    >
                        {{ $t('auth.require_new_password.change_action') }}
                    </button>
                </div>
            </Form>
            <div class="mt-6">
                <button
                    type="button"
                    class="link"
                    @click="backToSignIn"
                >
                    {{ $t('auth.sign_in_link') }}
                </button>
            </div>
        </div>
        <loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { AuthState, ChallengeName } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import Loader from '@/components/ui/Loader';
import { checkContact, dispatchAuthStateChangeEvent, dispatchToastHubEvent } from './helpers';

export default {
    name: 'RequireNewPassword',
    components: {
        Form,
        TextField,
        Loader,
    },

    mixins: [ValidatorMixin],
    props: {
        authData: {
            type: Object,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async completeNewPassword({ newPassword }) {
            this.loading = true;

            try {
                const user = await Auth.completeNewPassword(this.authData, newPassword);

                // prettier-ignore
                switch (user.challengeName) {
                case ChallengeName.SMSMFA:
                    dispatchAuthStateChangeEvent(AuthState.ConfirmSignIn, user);
                    break;
                case ChallengeName.MFASetup:
                    dispatchAuthStateChangeEvent(AuthState.TOTPSetup, user);
                    break;
                default:
                    await checkContact(user, dispatchAuthStateChangeEvent);
                }
            } catch (error) {
                dispatchToastHubEvent(error);
            } finally {
                this.loading = false;
            }
        },

        backToSignIn() {
            dispatchAuthStateChangeEvent(AuthState.SignIn);
        },
    },
};
</script>

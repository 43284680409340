import store from '@/store';

const createPermissionChecker = role => () => store.getters['auth/currentProfile']?.type === role;

const routes = [
    {
        name: 'messages.index',
        path: '/messages',
        component: () => {
            return import('@/views/messages/Index');
        },
        meta: {
            theme: 'messages',
            title: 'messages.list.menu',
            icon: 'bell',
            getCounterValue: () => store.getters['msgs/unreadTopicsCount'],
            permissionChecker: createPermissionChecker('resident'),
        },
    },
    {
        name: 'messages.details',
        path: '/messages/:topicId',
        component: () => {
            return import('@/views/messages/Details');
        },
        meta: {
            theme: 'messages',
            icon: 'bell',
            parent: 'messages.index',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
];

export default routes;

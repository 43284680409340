import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';

export default {
    computed: {
        ...mapGetters({
            unreadTopicsCount: 'msgs/unreadTopicsCount',
        }),
    },
    methods: {
        ...mapActions({
            setUnreadTopicsCount: 'msgs/setUnreadTopicsCount',
        }),
        processNotifications({ channel, body }) {
            if (channel === 'MSGS/update_unread_statistics') {
                this.setUnreadTopicsCount(body);
            }
        },
        initialize() {
            this.$msgsDataProvider
                .getStatistic('topics')
                .then(value => this.setUnreadTopicsCount(value))
                .catch(() => {});
        },
    },
    mounted() {
        this.initialize();

        EventBus.on('messaging-topic-read-status-update', this.initialize);
        EventBus.on('wst-notification', this.processNotifications);
    },
    beforeUnmount() {
        EventBus.off('messaging-topic-read-status-update', this.initialize);
        EventBus.off('wst-notification', this.processNotifications);
    },
};

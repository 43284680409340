<template>
    <div class="hidden md:flex md:flex-col md:justify-between w-64 bg-gray-200 py-4 px-4 sidebar">
        <div>
            <div class="w-24 mx-auto">
                <Icon name="quext" />
            </div>
            <ul class="mt-4">
                <li
                    v-for="(route, index) in routes"
                    :key="index"
                    class="flex my-2"
                >
                    <router-link
                        :to="{ name: route.name }"
                        class="flex items-center text-gray-600 hover:text-purple-500 font-ffdin font-normal text-sm py-2"
                    >
                        <Icon
                            v-if="route.icon"
                            :name="route.icon"
                            class="w-4 mr-2"
                        />
                        {{ $t(route.title) }}
                    </router-link>
                </li>
            </ul>
        </div>
        <div v-if="currentCommunity">
            <p class="uppercase font-bold text-xs community-name">
                {{ currentCommunity.name }}
            </p>
            <p class="address text-xs">
                {{ currentCommunity.address.addressLine1 }} <br />
                {{ currentCommunity.address.zip }} {{ currentCommunity.address.city }},
                {{ currentCommunity.address.state }}
            </p>
            <p class="text-xs">
                <span class="capitalize">{{ $t('common.main') }}</span>
                : {{ currentCommunity.phone }}
            </p>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin';
import AuthMixin from '@/mixins/AuthMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { mapGetters } from 'vuex';

export default {
    components: { Icon },

    mixins: [AuthMixin, NotifyMixin, AccountingQueryManager],

    props: {
        routes: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
        }),
    },

    watch: {
        currentProfile: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getCurrentCommunity();
                }
            },
        },
    },

    methods: {
        async getCurrentCommunity() {
            try {
                const community = await this.queryHandler('getByCommunityIdAndCustomerId', 'community', {
                    customerId: this.currentProfile.customerId,
                    communityId: this.currentProfile.communityId,
                });
                this.setCurrentCommunity(community);
            } catch (error) {
                this.notifyError(error.message);
            }
        },
    },
};
</script>

<style scoped>
.router-link-exact-active {
    @apply text-purple-600;
}
.community-name {
    letter-spacing: 0.045rem;
    line-height: 0.938rem;
}
.address {
    margin: 0.688rem 0;
}
.sidebar {
    min-width: 15.625rem;
}
</style>

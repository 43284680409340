<script>
import getEnvVariable from '@/utils/getEnvVariable';
import WSClient from '@/api/wst';

const url = getEnvVariable('VUE_APP_WST_API_URL');
const wsclient = new WSClient(url);

export default {
    name: 'ws-connector',

    setup() {
        return () => null;
    },

    mounted() {
        wsclient.connect();
    },

    beforeUnmount() {
        wsclient.disconnect();
    },
};
</script>

export const SOMETHING_WENT_WRONG = 'Something went wrong, please try again later.';
export const IDENTITY_VERIFICATION_ERROR = 'Something went wrong with the identity verification, please try again later';
export const EVALUATION_ERROR = 'Something went wrong with the identity evaluation, please try again later';
export const SAVING_ID_VERIFICATION_RESULTS_ERROR = 'Something went wrong saving identity verification results, please try again later';
export const UPDATE_APPLICATION_ERROR = 'Something went wrong updating the application';
export const FETCH_DOCUMENT_INSTANCE_ERROR = 'Something went wrong fetching the document, please try again later';
export const UPDATE_DOCUMENT_INSTANCE_ERROR = 'Something went wrong updating the document, please try again later';
export const SAVE_DOCUMENT_INSTANTCE_ERROR = 'Something went wrong saving the document, please try again later';
export const TRANSACTION_ERROR = 'There was an error with your trasaction. Please try again.';
export const TRANSACTION_DECLINED = 'Transaction declined. Please try again.';
export const TRANSACTION_CANCELED = 'Transaction canceled.';
export const PAYMENT_ERROR = 'Something went wrong saving your payment, please try again later';
export const CHARGE_CODES_ERROR = 'Something went wrong fetching the charge codes, please try again later';
export const PAYMENT_COMPLETED = 'COMPLETE';
export const PAYMENT_FAILED = 'FAILED';
export const BM_CREDENTIALS_ERROR = 'this community is not set up in blue moon!';
export const BM_PROPERTY_ERROR = 'An error occurred getting the property from Blue Moon';
export const PROFILE_TYPES = {
    resident: 'resident',
    applicant: 'applicant',
};

<template>
    <div>
        <div class="md:hidden bottom-panel flex justify-between items-center">
            <router-link
                v-for="(route, index) in navRoutes"
                :key="index"
                :to="{ name: route.name }"
                class="flex justify-center items-center w-12 h-12 relative"
            >
                <span
                    v-if="typeof route.getCounterValue === 'function' && route.getCounterValue() > 0"
                    class="counter"
                >
                    {{ getCounterText(route.getCounterValue()) }}
                </span>
                <icon
                    :name="route.icon"
                    class="block w-6 h-6 text-gray-400 nav-icon"
                ></icon>
            </router-link>
            <ActionButton :routes="actionRoutes" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import ActionButton from '@/components/root/ActionButton';
export default {
    components: { ActionButton, Icon },
    props: {
        navRoutes: {
            type: Array,
            required: true,
        },

        actionRoutes: {
            type: Array,
            required: true,
        },
    },

    methods: {
        getCounterText(num) {
            return num <= 9 ? num : '9+';
        },
    },
};
</script>

<style scoped>
.bottom-panel {
    @apply border border-gray-200 px-6 py-3;
    height: 4.5rem;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}
.router-link-exact-active {
    @apply bg-gray-200 rounded-xl;
}
.router-link-exact-active .nav-icon {
    @apply text-gray-700;
}
.counter {
    @apply absolute left-2.5 top-1.5 w-4 h-4 rounded-full flex items-center justify-center;
    @apply bg-brown-600 text-white text-2xs font-ffdin font-medium leading-none;
}
</style>

export default {
    categories: 'categories',
    categoriesRICount: 'categories-ri-count',

    rentableItems: 'ri',
    items: 'ri/{riId}/items',

    reservations: {
        create: ['POST', 'ri/{riId}/reservations'],
        getList: ['GET', '/reservations'],
        getOne: ['GET', '/reservations/{id}'],
        cancel: ['POST', '/reservations/cancel'],
        availableDays: ['GET', '/ri/{riId}/availability/days'],
        availableTime: ['GET', '/ri/{riId}/availability/time'],
        validate: ['POST', 'ri/{riId}/reservations/validate-on-create'],
    },
    permissions: 'my/permissions',
};

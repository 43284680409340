import { createRouter, createWebHistory } from 'vue-router';
import { Auth } from 'aws-amplify';
import store from '@/store';

/**
 * Vue defined routes
 */
let routes = [];

/**
 * Dynamically load custom route files so this file does not grow big.
 * All nested route files will be loaded except `router/index.js`
 */
let files = require.context('./', true, /\.js$/i);

files.keys().map(key => {
    let filename = key.split('/').pop().split('.')[0];
    let path = key.split('/');
    path.shift();
    path.pop();

    if (filename === 'index' && !path.length) return;

    let filepath = './';
    filepath += path.length ? path.join('/') + '/' : '';
    let fileRoutes = require(filepath + filename + '.js');
    routes = routes.concat(fileRoutes.default);
});

/**
 * Initialize the router.
 */
const router = createRouter({
    routes: routes.map(route => {
        if (route.meta.parent) {
            return {
                ...route,
                components: {
                    default: routes.find(r => r.name === route.meta.parent)?.component,
                    slideover: route.component,
                },
            };
        } else {
            return route;
        }
    }),
    history: createWebHistory(),
});

router.beforeResolve((to, from, next) => {
    switch (true) {
    case to.path === '/reset-password' || to.path === '/redirect':
        if (store.getters['auth/authenticated']) {
            next({
                path: '/',
                replace: true,
            });
        } else {
            next();
        }
        break;

    case to.path === '/logout':
        next({
            path: '/',
            replace: true,
        });
        Auth.signOut();
        break;

    case to.meta?.permissionChecker && !to.meta.permissionChecker():
        next({ name: 'notAuthorized' });
        break;

    default:
        next();
    }
});

router.afterEach(to => {
    const theme = to.meta?.theme ?? 'default';

    const { classList } = document.body;

    classList.remove(...Array.from(classList.values()).filter(c => c.endsWith('-theme')));
    classList.add(`${theme}-theme`);
});

export default router;

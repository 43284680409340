export { checkContact, handleSignIn } from '@aws-amplify/ui-components/dist/collection/common/auth-helpers';

export { dispatchAuthStateChangeEvent, dispatchToastHubEvent } from '@aws-amplify/ui-components/dist/collection/common/helpers';

export const getExternalAction = encodedQuery => {
    if (!encodedQuery) {
        return {};
    }
    const query = Buffer.from(encodedQuery, 'base64').toString('utf-8');

    return query.split('&').reduce((acc, item) => {
        const [key, value] = item.split('=');
        return {
            ...acc,
            [key]: value,
        };
    }, {});
};

export const autoSignInActions = ['CREATE_YOUR_PASSWORD', 'RESET_YOUR_PASSWORD'];

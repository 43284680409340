import { CLEAR_STATE, SET_CHARGE_TOTALS, SET_TPP_WIDGET_VISIBILITY, SET_ONE_YEAR_PERIODS } from './mutations';

const clearState = ({ commit }) => commit(CLEAR_STATE);

const setChargeTotals = ({ commit }, payload) => commit(SET_CHARGE_TOTALS, payload);

const setTPPWidgetVisibility = ({ commit }, payload) => commit(SET_TPP_WIDGET_VISIBILITY, payload);

const setOneYearPeriods = ({ commit }, payload) => commit(SET_ONE_YEAR_PERIODS, payload);

export default {
    clearState,
    setChargeTotals,
    setTPPWidgetVisibility,
    setOneYearPeriods,
};

const routes = [
    {
        name: 'dashboard.index',
        path: '/',
        component: () => import('@/views/dashboard/Index'),
        meta: {
            theme: 'payments',
            title: 'dashboard.menu',
            icon: 'home',
        },
    },
];

export default routes;

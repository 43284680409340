import { h } from 'vue';
import store from '@/store';

const createPermissionChecker = role => () => store.getters['auth/currentProfile']?.type === role;

const routes = [
    {
        name: 'profile.index',
        path: '/profile',
        component: () => {
            return import('@/views/profile/Index');
        },
        meta: {
            title: 'profile.menu',
            icon: 'user',
            // permissions: ['applicant', 'resident', 'guarantor'],
            permissionChecker: createPermissionChecker('resident'),
        },
    },
    {
        name: 'profile.change_password',
        path: '/profile/change-password',
        component: () => {
            return import('@/views/profile/ChangePassword');
        },
        meta: {
            title: 'profile.change_password',
            icon: 'user',
            permissions: ['applicant', 'resident', 'guarantor'],
            parent: 'profile.index',
        },
    },
    {
        name: 'profile.logout',
        path: '/logout',
        component: () => Promise.resolve(h('div')),
        meta: {
            title: 'app.logout_menu',
            icon: 'logout',
            // permissions: ['applicant', 'resident', 'guarantor'],
        },
    },
];

export default routes;

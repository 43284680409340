export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_TOTALS = 'SET_TOTALS';
export const SET_CHARGE_TOTALS = 'SET_CHARGE_TOTALS';
export const SET_TPP_WIDGET_VISIBILITY = 'SET_TPP_WIDGET_VISIBILITY';
export const SET_ONE_YEAR_PERIODS = 'SET_ONE_YEAR_PERIODS';

export default {
    [CLEAR_STATE]: state => {
        state.totals = [];
        state.chargeTotals = null;
    },
    [SET_TOTALS]: (state, payload) => {
        state.totals = payload;
    },
    [SET_CHARGE_TOTALS]: (state, payload) => {
        state.chargeTotals = payload;
    },
    [SET_TPP_WIDGET_VISIBILITY]: (state, payload) => {
        state.isTPPWidgetVisible = payload;
    },
    [SET_ONE_YEAR_PERIODS]: (state, payload) => {
        state.oneYearPeriods = payload;
    },
};

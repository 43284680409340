import EventBus from '@/utils/EventBus';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    mixins: [NotifyMixin],

    data() {
        return {
            loading: false,
            hasMore: true,
            items: [],

            // overridable
            ITEMS_LIMIT: 20,
            CREATE_ITEM_NOTIFICATION_ID: '',
            UPDATE_ITEM_NOTIFICATION_ID: '',
        };
    },

    methods: {
        loadItems: () => {},

        loadInitial() {
            this.loading = true;
            this.loadItems({ limit: this.ITEMS_LIMIT })
                .then(({ items }) => {
                    this.items = items;
                    this.hasMore = this.items.length >= this.ITEMS_LIMIT;
                })
                .catch(e => {
                    this.notifyError(e.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        loadMore() {
            if (!this.hasMore) return;

            this.loading = true;

            this.loadItems({
                token: this.items[this.items.length - 1].token,
                limit: this.ITEMS_LIMIT,
            })
                .then(({ items }) => {
                    this.hasMore = items.length >= this.ITEMS_LIMIT;

                    this.items.push(...items);
                })
                .catch(e => {
                    this.notifyError(e.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        processNotifications(notification) {
            switch (notification.channel) {
            case this.CREATE_ITEM_NOTIFICATION_ID:
                this.items.unshift(notification.body);
                break;

            case this.UPDATE_ITEM_NOTIFICATION_ID:
                this.items[this.items.findIndex(({ id }) => id === notification.body.value.id)] = notification.body;
                this.items = [...this.items];
                break;

            default:
                this.processAdditionalNotifications(notification);
            }
        },

        processAdditionalNotifications: () => {},
    },

    mounted() {
        this.loadInitial();
        EventBus.on('wst-notification', this.processNotifications);
    },

    beforeUnmount() {
        EventBus.off('wst-notification', this.processNotifications);
    },
};

import store from '@/store';

const createPermissionChecker = role => () => store.getters['auth/currentProfile']?.type === role;

const routes = [
    {
        name: 'renewal.index',
        path: '/renewal-offer',
        component: () => {
            return import('@/views/renewal/Index');
        },
        meta: {
            theme: 'renewal',
            title: 'renewal.menu',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
];

export default routes;

import { Auth } from 'aws-amplify';

import getEnvVariable from '@/utils/getEnvVariable';

export function configureAwsAmplify() {
    let cookieStorage;

    if (navigator?.cookieEnabled) {
        // prevents login issues on feature branches
        const cookieStorageDomain = getEnvVariable('VUE_APP_COGNITO_COOKIE_DOMAIN')?.toLowerCase();
        const isDomainMatch = cookieStorageDomain && location.hostname.endsWith(cookieStorageDomain);
        if (isDomainMatch) {
            cookieStorage = {
                domain: cookieStorageDomain,
                expires: 1, // 1 day
                sameSite: 'strict',
                secure: cookieStorageDomain !== 'localhost' && cookieStorageDomain !== '127.0.0.1',
            };
        }
    }

    return Auth.configure({
        mandatorySignIn: true,
        cookieStorage,
        region: getEnvVariable('VUE_APP_COGNITO_REGION'),
        userPoolId: getEnvVariable('VUE_APP_COGNITO_USERPOOL_ID'),
        userPoolWebClientId: getEnvVariable('VUE_APP_COGNITO_USERPOOL_WEBCLIENT_ID'),
    });
}

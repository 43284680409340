import store from '@/store';

const createPermissionChecker = permissionId => () => store.getters['ri/hasPermission'](permissionId);

const routes = [
    {
        name: 'rentableItems.index',
        path: '/rentable-items',
        component: () => {
            return import('@/views/rentableItems/Index');
        },
        meta: {
            theme: 'rentable-items',
            title: 'ri.list.menu',
            icon: 'timer',
            permissionChecker: createPermissionChecker('RP_RI_VIEW'),
        },
    },
    {
        name: 'rentableItems.details',
        path: '/rentable-items/:riId',
        component: () => {
            return import('@/views/rentableItems/RIDetails');
        },
        meta: {
            theme: 'rentable-items',
            title: 'ri.list.menu',
            icon: 'timer',
            parent: 'rentableItems.index',
            permissionChecker: createPermissionChecker('RP_RI_VIEW'),
        },
    },
    {
        name: 'reservations.create',
        path: '/rentable-items/:riId/create',
        component: () => {
            return import('@/views/rentableItems/CreateReservation');
        },
        meta: {
            theme: 'rentable-items',
            icon: 'timer',
            parent: 'rentableItems.index',
            permissionChecker: createPermissionChecker('RP_RESERVATIONS_EDIT'),
        },
    },
    {
        name: 'reservations.details',
        path: '/reservations/:reservationId',
        component: () => {
            return import('@/views/rentableItems/ReservationDetails');
        },
        meta: {
            theme: 'rentable-items',
            icon: 'timer',
            parent: 'rentableItems.index',
            permissionChecker: createPermissionChecker('RP_RESERVATIONS_VIEW_DETAILS'),
        },
    },
];

export default routes;

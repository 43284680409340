export default {
    messages: {
        getList: ['GET', '/chats/{id}/messages'],
        create: ['POST', '/chats/{id}/messages'],
        lastRead: ['PATCH', '/chats/{id}/last-read-message'],
    },
    chats: {
        getOne: ['GET', '/chats/{id}'],
    },
};

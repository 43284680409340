<template>
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->
    <amplify-authenticator
        ref="auth"
        username-alias="username"
    >
        <!--    TODO: implement sign-up flow-->
        <!--    <div slot="sign-up"></div>-->
        <SignIn
            slot="sign-in"
            :action="action"
            @submitted="({ username }) => (user = username)"
        />
        <ForgotPassword slot="forgot-password" />
        <RequireNewPassword
            slot="require-new-password"
            :authData="authData"
        />
        <slot
            v-if="authenticated"
            v-bind:logout="logout"
        ></slot>
    </amplify-authenticator>
</template>

<script>
import { useI18n } from 'vue-i18n';
import SignIn from '@/components/auth/SignIn';
import ForgotPassword from '@/components/auth/ForgotPassword';
import RequireNewPassword from '@/components/auth/RequireNewPassword';
import AuthMixin from '@/mixins/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { Auth, Hub } from 'aws-amplify';
import { AUTH_CHANNEL, AUTH_STATE_CHANGE_EVENT, UI_AUTH_CHANNEL, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { mapActions } from 'vuex';
import { AuthState } from '@aws-amplify/ui-components/dist/collection/common/types/auth-types';
import { dispatchAuthStateChangeEvent } from '@aws-amplify/ui-components/dist/collection/common/helpers';
import { getExternalAction } from './helpers';

export default {
    name: 'Authenticator',

    components: { RequireNewPassword, ForgotPassword, SignIn },

    mixins: [AuthMixin, NotifyMixin],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            authData: undefined,
            action: null,
        };
    },

    mounted() {
        const unsubscribe = onAuthUIStateChange(() => {
            const style = document.createElement('style');
            style.innerHTML = `
                amplify-toast {
                    position: fixed;
                    width: 100%;
                    --background-color: #B9320F;
                    --color: #FFFFFF;
                    --font-family: sofia-pro, sans-serif;
                    --font-size: 16px;
                    --close-icon-color: #FFFFFF;
                    --close-icon-hover: #FFFFFF;
                }
            `;
            this.$refs.auth.shadowRoot.appendChild(style);
            unsubscribe();

            this.checkResetPasswordRoute();

            this.checkExternalAction();
        });

        this.getProfile();

        Hub.listen(AUTH_CHANNEL, async ({ payload: { event, data } }) => {
            // prettier-ignore
            switch (event) {
            case 'signIn': {
                const { attributes, username } = await Auth.currentAuthenticatedUser();
                this.signIn({
                    ...data.attributes,
                    ...attributes,
                    username,
                });

                this.getProfile();
                break;
            }
            case 'signOut':
                this.signOut();
                this.clearState();
                break;
            }
        });

        Hub.listen(UI_AUTH_CHANNEL, ({ payload: { data, event, message } }) => {
            this.authData = data;

            if (event === AUTH_STATE_CHANGE_EVENT && message === AuthState.SignedIn) {
                const { attributes, username } = data;

                this.signIn({
                    ...attributes,
                    username,
                });
                this.getProfile();
            }
        });
    },

    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
            signOut: 'auth/signOut',
            clearState: 'application/clearState',
        }),

        async logout() {
            try {
                await Auth.signOut();
            } catch (error) {
                this.notifyError(this.t('auth.logout_error'), error);
            }
        },

        getProfile() {
            return this.$authDataProvider
                .get('profile')
                .then(({ content }) => {
                    this.setProfiles(content);
                })
                .catch(() => {});
        },

        checkResetPasswordRoute() {
            if (!this.authenticated && this.$route?.path === '/reset-password') {
                this.$nextTick(() => {
                    dispatchAuthStateChangeEvent(AuthState.ForgotPassword, {
                        codeSent: true,
                        username: this.$route.query?.username,
                    });
                });
            }
        },

        checkExternalAction() {
            if (!this.authenticated && this.$route?.path === '/redirect') {
                const encodedQuery = this.$route.fullPath.split('?')[1];
                this.action = getExternalAction(encodedQuery);
            } else {
                this.action = {};
            }
        },
    },
};
</script>

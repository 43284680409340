import moment from 'moment-timezone';
import store from '@/store';

export const currentDate = moment(new Date()).format('MM/DD/YYYY');

export const dateFormat = 'MM/DD/YYYY';

export const dateFormatter = value => (value ? moment(value).format(dateFormat) : '');

export const getPeriodDate = ({ month, period }) => `${month} ${moment(period).year()}`;

export const getFirstAndLastDatesMonth = ({ month }) => {
    return {
        startDate: moment(month).startOf('month').toISOString(),
        endDate: moment(month).endOf('month').toISOString(),
    };
};

const communityTimezone = store?.getters['auth/currentProfile']?.communityTimezone;

export const getYear = val => val.getFullYear();

export const getMonthIndex = val => val.getUTCMonth();

export const startAndEndOfMonth = ({ year, monthIndex, timezone = communityTimezone, keepLocalTime = false }) => {
    const startOfMonth = moment.tz([year, monthIndex], timezone).startOf('month').utcOffset(0, keepLocalTime);
    const endOfMonth = moment.tz([year, monthIndex], timezone).endOf('month').utcOffset(0, keepLocalTime);

    return {
        startDate: startOfMonth.toISOString(),
        endDate: endOfMonth.toISOString(),
    };
};

export const dateCalculator = (value, operator, quantity, duration) => {
    return moment.utc(value)[operator](quantity, duration).startOf('day').toISOString();
};

import { createApp } from 'vue';
import { configureCompat } from '@vue/compat';

configureCompat({
    INSTANCE_LISTENERS: false,
    INSTANCE_SCOPED_SLOTS: false,
    RENDER_FUNCTION: false,
    COMPONENT_V_MODEL: false,
});

import { i18n } from './i18n';

// Vue router setup
import router from './router';

// Vuex store setup
import store from './store';

// Tailwind setup
import './assets/styles.css';

// AWS Cognito setup
import { configureAwsAmplify } from '@/aws_config';
import { defineCustomElements } from '@aws-amplify/ui-components/loader';

defineCustomElements(window);

configureAwsAmplify();

import SreqDataProvider from '@/api/sreq';
import ChatDataProvider from '@/api/chat';
import AuthDataProvider from '@/api/auth';
import MessagesDataProvider from '@/api/messages';
import AttachmentsDataProvider from '@/api/attachments';
import RentableItemDataProvider from '@/api/ri';
import BMServiceDataProvider from '@/api/blueMoonService';
import LeasingServiceDataProvider from '@/api/leasingService';
import IntegrationsServiceDataProvider from '@/api/integrations';
import AccountingServiceDataProvider from '@/api/accounting';
import NotificationsDataProvider from '@/api/notifications';
import ContactsDataProvider from '@/api/contact';
import PartnerDataProvider from '@/api/partners';

import App from './App.vue';

const app = createApp(App);

app.use(router);

app.use(store);

app.use(i18n);

// Service requests API data provider
app.use(SreqDataProvider, { name: 'sreqDataProvider' });

// Chats API data provider
app.use(ChatDataProvider, { name: 'chatDataProvider' });

// Auth API data provider
app.use(AuthDataProvider, { name: 'authDataProvider' });

// Messages API data provider
app.use(MessagesDataProvider, { name: 'msgsDataProvider' });

// Attachments service data provider
app.use(AttachmentsDataProvider, { name: 'attachmentsDataProvider' });

// Rentable items API data provider
app.use(RentableItemDataProvider, { name: 'riDataProvider' });

// Leasing Service data provider
app.use(LeasingServiceDataProvider, { name: 'leasingServiceDataProvider' });

// Blue Moon Service data provider
app.use(BMServiceDataProvider, { name: 'bmServiceDataProvider' });

// Integrations Service (Transunion) data provider
app.use(IntegrationsServiceDataProvider, { name: 'integrationsServiceDataProvider' });

app.use(AccountingServiceDataProvider, { name: 'accountingServiceDataProvider' });

app.use(NotificationsDataProvider, { name: 'notificationsDataProvider' });

app.use(ContactsDataProvider, { name: 'contactsDataProvider' });

// Partner service data Provider
app.use(PartnerDataProvider, { name: 'partnerDataProvider' });

app.mount('#app');

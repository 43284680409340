import store from '@/store';

const createPermissionChecker = role => () => store.getters['auth/currentProfile']?.type === role;
const isOccupancyCurrent = () => store.getters['leasing/getOccupancyData']?.occupancyType === 'current';

const routes = [
    {
        name: 'serviceRequests.index',
        path: '/service-requests',
        component: () => {
            return import('@/views/serviceRequests/Index');
        },
        meta: {
            theme: 'requests',
            title: 'sreq.list.menu',
            icon: 'service',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
    {
        name: 'serviceRequests.create',
        path: '/service-requests/create',
        component: () => {
            return import('@/views/serviceRequests/Create');
        },
        meta: {
            theme: 'requests',
            title: 'sreq.create.menu',
            icon: 'service',
            parent: 'serviceRequests.index',
            permissionChecker: () => createPermissionChecker('resident')() && isOccupancyCurrent(),
        },
    },
    {
        name: 'serviceRequests.details',
        path: '/service-requests/:requestId',
        component: () => {
            return import('@/views/serviceRequests/Details');
        },
        meta: {
            theme: 'requests',
            icon: 'service',
            parent: 'serviceRequests.index',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
    {
        name: 'serviceRequests.timeInOutLog',
        path: '/service-requests/:requestId/time-in-out-log',
        component: () => {
            return import('@/views/serviceRequests/TimeInOutLog');
        },
        meta: {
            theme: 'requests',
            icon: 'service',
            parent: 'serviceRequests.details',
        },
    },
];

export default routes;

import store from '@/store';

const createPermissionChecker = role => () => store.getters['auth/currentProfile']?.type === role;

const routes = [
    {
        name: 'unit.index',
        path: '/unit',
        component: () => {
            return import('@/views/unit/Index');
        },
        meta: {
            title: 'unit.menu',
            icon: 'unit',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
];

export default routes;

import { isEqual } from 'lodash-es';
import Queue from 'queue-promise';
import AuthMixin from '@/mixins/AuthMixin';
import permissionFetchers from '@/api/permission_fetchers';

const REFRESH_INTERVAL = 5 * 60 * 1000;

export default {
    mixins: [AuthMixin],

    data() {
        return {
            fetchAppPermissionsIntervalId: undefined,

            // use queue for permission Promises to prevent requests from racing
            permissionsPromisesQueue: new Queue({
                concurrent: 1,
                interval: 0,
            }),

            availableRoutes: [],
        };
    },

    methods: {
        fetchAppPermissions() {
            const appAccesses = Object.keys(permissionFetchers);

            if (this.permissionsPromisesQueue.shouldRun) {
                this.permissionsPromisesQueue.clear();
            }

            Object.keys(this.appsPermissions)
                .filter(code => appAccesses.find(app => app === code) === undefined)
                .forEach(code => this.clearAppPermissions(code));

            if (!appAccesses) return;

            for (const appCode of appAccesses) {
                if (permissionFetchers[appCode]) {
                    this.permissionsPromisesQueue.enqueue(() =>
                        permissionFetchers[appCode](this).then(permissions => {
                            this.setAppPermissions({ appCode, permissions });
                        })
                    );
                }
            }
        },

        startAppPermissionsPolling() {
            this.fetchAppPermissions();
            // to be replaced with WST events listening somewhere in the future
            clearInterval(this.fetchAppPermissionsIntervalId);
            this.fetchAppPermissionsIntervalId = setInterval(this.fetchAppPermissions, REFRESH_INTERVAL);
        },

        updateAvailableRoutes() {
            const newAvailableRoutes = this.$router
                .getRoutes()
                .filter(route => !route.meta?.permissionChecker || route.meta.permissionChecker());
            const includeCurrentRoute = newAvailableRoutes.find(route => route.name === this.$route.name);

            if (includeCurrentRoute) {
                this.availableRoutes = newAvailableRoutes;
            } else {
                this.$router.push({ name: 'dashboard.index' }).then(() => {
                    this.availableRoutes = newAvailableRoutes;
                });
            }
        },
    },

    watch: {
        currentProfile: {
            handler: function (val, oldVal) {
                //reset currentProfile when not found in fetched profiles list
                if (!val) {
                    clearInterval(this.fetchAppPermissionsIntervalId);
                    // redirect if currentProfile is not actual anymore
                    this.$router.push({ name: 'dashboard.index' });
                }
                // update current Profile
                else if (!isEqual(val, oldVal)) {
                    this.startAppPermissionsPolling();
                }
            },
            deep: true,
        },
        authenticated: {
            handler: function (value) {
                if (!value) {
                    clearInterval(this.fetchAppPermissionsIntervalId);
                } else {
                    this.updateAvailableRoutes();
                }
            },
            immediate: true,
        },
        profiles: {
            handler: function () {
                // fetched profiles list and currentProfile is included
                if (this.currentProfile) {
                    this.startAppPermissionsPolling();
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.permissionsPromisesQueue.on('end', this.updateAvailableRoutes);
    },

    beforeUnmount() {
        clearInterval(this.fetchAppPermissionsIntervalId);
    },
};

import store from '@/store';

const createPermissionChecker = role => () => store.getters['application/hasPermissionByRole'](role);

const routes = [
    {
        name: 'application_service.index',
        path: '/application-service/rental-application-form',
        component: () => {
            return import('@/views/applicationService/Index.vue');
        },
        meta: {
            theme: 'payments',
            title: 'Rental Application',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('applicant'),
        },
    },
    {
        name: 'application_service.review-application',
        path: '/application-service/review-application',
        component: () => {
            return import('@/views/applicationService/ReviewRentalForm.vue');
        },
        meta: {
            theme: 'payments',
            title: 'Rental Application',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('applicant'),
        },
    },
    {
        name: 'application_service.esign-application',
        path: '/application-service/sign-application',
        component: () => {
            return import('@/views/applicationService/SignRentalForm.vue');
        },
        meta: {
            theme: 'payments',
            title: 'Rental Application',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('applicant'),
        },
    },
    {
        name: 'application_service.pre_evaluation_form',
        path: '/application-service/pre-evaluation-form',
        component: () => {
            return import('@/views/applicationService/Pre-EvaluationFrom.vue');
        },
        meta: {
            theme: 'payments',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('applicant'),
        },
    },
    {
        name: 'application_service.application_payment',
        path: '/application-service/rental-application-payment',
        component: () => {
            return import('@/views/applicationService/Payment.vue');
        },
        meta: {
            theme: 'payments',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('applicant'),
        },
    },
    {
        name: 'application_service.application_payment_guarantor',
        path: '/application-service/guarantor-application-payment',
        component: () => {
            return import('@/views/guarantorPreLeaseContract/Payment.vue');
        },
        meta: {
            theme: 'payments',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('guarantor'),
        },
    },
    {
        name: 'application_service.background_screening',
        path: '/application-service/rental-application-background-screening',
        component: () => {
            return import('@/views/applicationService/TransUnion.vue');
        },
        meta: {
            theme: 'payments',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('applicant'),
        },
    },
    {
        name: 'application_service.background_screening_guarantor',
        path: '/application-service/guarantor-id-verification',
        component: () => {
            return import('@/views/applicationService/TransUnionGuarantor.vue');
        },
        meta: {
            theme: 'payments',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('guarantor'),
        },
    },
    {
        name: 'application_service.confirmation',
        path: '/application-service/rental-application-confirmation',
        component: () => {
            return import('@/views/applicationService/Confirmation.vue');
        },
        meta: {
            theme: 'payments',
            icon: 'file-text',
        },
    },
    {
        name: 'application_service.guarantor_contract',
        path: '/guarantor-contract',
        component: () => {
            return import('@/views/guarantorPreLeaseContract/Index.vue');
        },
        meta: {
            theme: 'payments',
            title: 'Guarantor Pre-Lease Contract',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('guarantor'),
        },
    },
    {
        name: 'application_service.guarantor_contract_review',
        path: '/guarantor-contract/review',
        component: () => {
            return import('@/views/guarantorPreLeaseContract/GuarantorContractReview.vue');
        },
        meta: {
            theme: 'payments',
            title: 'Guarantor Pre-Lease Contract - Review',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('guarantor'),
        },
    },
    {
        name: 'application_service.guarantor_contract_sign',
        path: '/guarantor-contract/e-signature',
        component: () => {
            return import('@/views/guarantorPreLeaseContract/GuarantorContractSign.vue');
        },
        meta: {
            theme: 'payments',
            title: 'Guarantor Pre-Lease Contract - Signature',
            icon: 'file-text',
            permissionChecker: createPermissionChecker('guarantor'),
        },
    },
    {
        name: 'application_service.guarantor_lease',
        path: '/guarantor-lease',
        component: () => {
            return import('@/views/guarantorPreLeaseContract/LeaseGuarantor.vue');
        },
        meta: {
            permissions: ['guarantor'],
            theme: 'payments',
            // title: 'Guarantor Pre-Lease Contract',
            icon: 'file-text',
        },
    },
];

export default routes;

import { Auth } from 'aws-amplify';
import store from '@/store';
import Axios from 'axios';
import { stringify } from 'query-string';
import { pickBy } from 'lodash-es';

const httpClient = baseURL => request =>
    Auth.currentSession().then(session =>
        Axios.request({
            ...request,
            baseURL,
            paramsSerializer: params => stringify(params),
            headers: pickBy({
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                'X-Quext-Resident-Cognito-Id': store.getters['auth/cognitoUserId'],
                'X-Quext-Resident-Profile-Id': store.getters['auth/currentProfile']?.profileId,
                'X-Quext-Occupancy-Id': store.getters['auth/currentProfile']?.occupancyId,
                'X-Quext-Community-Id': store.getters['auth/currentProfile']?.communityId,
                'X-Quext-Customer-Id': store.getters['auth/currentProfile']?.customerId,
                ...request.headers,
            }),
        }).catch(error => {
            const response = error?.response;
            const message = response?.data?.message || error.message;

            if (response?.status === 401) {
                Auth.signOut();
            }

            return Promise.reject({
                message,
                ...response,
            });
        })
    );

export default httpClient;

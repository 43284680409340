<template>
    <div class="w-full max-w-md p-10">
        <div class="flex flex-col items-center">
            <div class="font-ffdin font-bold text-2xl uppercase text-center mb-6">{{ $t('app.title') }}</div>
            <icon
                name="sign-in"
                class="mb-6"
            ></icon>
            <Form
                ref="form"
                :submit="signIn"
            >
                <TextField
                    type="text"
                    name="username"
                    :label="$t('auth.username')"
                    :validate="required"
                />
                <TextField
                    name="password"
                    :label="$t('auth.password')"
                    type="password"
                    :validate="required"
                />
                <div class="form-field flex justify-end">
                    <button
                        type="button"
                        class="link"
                        @click="forgotPassword"
                    >
                        {{ $t('auth.forgot_password') }}
                    </button>
                </div>
                <div class="form-field">
                    <button
                        class="btn btn-primary w-full"
                        type="submit"
                        :disabled="loading"
                    >
                        {{ $t('auth.sign_in_action') }}
                    </button>
                </div>
            </Form>
        </div>
        <loader
            :loading="loading || checkingAction"
            :backdrop="true"
        />
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import TextField from '@/components/form/TextField';
import Form from '@/components/form/Form';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import { AuthState } from '@aws-amplify/ui-components';
import { dispatchAuthStateChangeEvent, handleSignIn, autoSignInActions } from './helpers';
import Loader from '@/components/ui/Loader';

export default {
    name: 'SignIn',
    components: {
        Icon,
        Form,
        TextField,
        Loader,
    },

    mixins: [ValidatorMixin],

    props: {
        action: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            checkingAction: true,
        };
    },

    mounted() {
        const unsubscribeAction = this.$watch('action', action => {
            if (action) {
                this.handleExternalAction(action);
                unsubscribeAction();
            }
        });
    },

    methods: {
        async signIn({ username, password }) {
            this.loading = true;
            await handleSignIn(username, password, dispatchAuthStateChangeEvent);
            this.$refs.form.$refs.form.finalForm.change('password', '');
            this.$refs.form.$refs.form.finalForm.resetFieldState('password');
            this.loading = false;
        },

        forgotPassword() {
            dispatchAuthStateChangeEvent(AuthState.ForgotPassword);
        },

        async handleExternalAction({ action, username, password }) {
            if (autoSignInActions.includes(action)) {
                await handleSignIn(username, password, dispatchAuthStateChangeEvent);
            }
            this.checkingAction = false;
        },
    },
};
</script>

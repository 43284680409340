export default {
    serviceRequests: {
        getList: ['GET', '/requests'],
        getOne: ['GET', '/requests/{id}'],
        getTimeInOutLogs: ['GET', '/requests/{id}/time-in-out-logs'],
        create: ['POST', '/requests/multiple'],
        update: ['PATCH', '/requests/{id}'],
        cancel: ['PUT', '/requests/{id}/cancel'],
        accept: ['PUT', '/requests/{id}/accept'],
        decline: ['PUT', '/requests/{id}/decline'],
        getSchema: ['GET', '/requests/status-schema'],
    },
    communitySettings: {
        getOne: ['GET', '/community-settings'],
    },
    locations: '/locations',
    appliances: '/locations/{unitId}/appliances',
    profile: '/profile',
    categories: '/references/request-categories',
};

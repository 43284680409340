import store from '@/store';

const createPermissionChecker = role => () => store.getters['auth/currentProfile']?.type === role;

const routes = [
    {
        name: 'payments.index',
        path: '/payments',
        component: () => {
            return import('@/views/payments/Index');
        },
        meta: {
            theme: 'payments',
            title: 'payments.list.menu',
            icon: 'dollar',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
    {
        name: 'payments.create',
        path: '/payments/create',
        component: () => {
            return import('@/views/payments/Create');
        },
        meta: {
            theme: 'payments',
            title: 'payments.create.menu',
            icon: 'card',
            parent: 'payments.index',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
    {
        name: 'payments.edit',
        path: '/payments/edit',
        component: () => {
            return import('@/views/payments/Edit');
        },
        meta: {
            theme: 'payments',
            title: 'payments.edit.menu',
            parent: 'payments.index',
            permissionChecker: createPermissionChecker('resident'),
        },
    },
];

export default routes;

import getEnvVariable from '@/utils/getEnvVariable';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import httpClient from '@/api/httpClient';
import resources from './resources';
import DataProvider from '@/api/DataProvider';

const baseUrl = getEnvVariable('VUE_APP_CHAT_API_URL');

const httpAdapter = new HttpAdapter(httpClient(baseUrl), resources, {
    afterRequestInterceptor: ({ response }) => response,
});

export default new DataProvider(httpAdapter);

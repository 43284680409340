export default {
    charge_codes:
        '/resident/v1/codes?sortDirection={sortDirection}&sortField={sortField}&chargeCodeCategoryAlias={chargeCodeCategoryAlias}&codeType={codeType}',
    application_fee: '/resident/v1/fee-pay/{accountId}',
    resident: {
        currentUser: ['GET', '/resident/v1/user'],
        getReceivableBalances: ['GET', '/resident/v1/receivable/{accountId}'],
        createPayment: ['POST', '/resident/v1/payment/{accountId}'],
        getChargeTotals: ['GET', '/resident/v1/totals/{accountId}'],
        getListCharges: ['GET', '/resident/v1/charges'],
        getDeposits: ['GET', '/resident/v1/deposits/{accountId}'],
        checkUnpaidCharges: ['GET', '/resident/v1/check-unpaid-charges/{accountId}'],
        getTotals: ['GET', '/resident/v1/totals-by-month/{accountId}'],
        getAvailablePaymentMethods: ['GET', '/resident/v1/accounts/{accountId}/payment-methods'],
        getPaymentToken: ['GET', '/resident/v1/payment/token'],
    },
    community: {
        getByCommunityIdAndCustomerId: ['GET', '/resident/auth/v1/customers/{customerId}/communities/{communityId}'],
    },
};

<template>
    <teleport to="#slideovers">
        <transition
            appear
            name="slide"
        >
            <div
                class="slideover fixed z-40 top-0 bottom-0 left-0 w-full"
                :class="{ 'is-page': isPage }"
            >
                <div
                    class="absolute backdrop top-0 bottom-0 left-0 right-0"
                    @click="handleClose"
                ></div>
                <div class="slide-panel absolute right-0 top-0 h-full shadow-2xl flex flex-col">
                    <icon
                        name="close"
                        class="absolute z-40 top-5 right-5 h-6 w-6 cursor-pointer"
                        @click="handleClose"
                    />
                    <slot></slot>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    props: {
        isPage: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['close'],

    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.slideover.is-page .slide-panel {
    @apply w-full xl:w-1/3 md:w-2/4 md:rounded-l-2xl bg-white;
}

.slideover:not(.is-page) .slide-panel {
    @apply w-2/3 md:w-1/3 rounded-l-2xl bg-gray-700 text-gray-200;
}

.slide-panel {
    @apply overflow-hidden;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.5s ease;
}

.slide-enter-active .backdrop,
.slide-leave-active .backdrop {
    transition: all 0.5s ease;
}

.slide-enter-from .backdrop,
.slide-leave-to .backdrop {
    opacity: 0;
}

.slide-enter-active .slide-panel,
.slide-leave-active .slide-panel {
    transition: all 0.5s ease;
    transform: translateX(0);
}

.slide-enter-from .slide-panel,
.slide-leave-to .slide-panel {
    transform: translateX(100%);
}
</style>

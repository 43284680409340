<template>
    <div>
        <div
            class="relative"
            @click="isOpen = !isOpen"
        >
            <icon
                name="bell"
                class="w-8 h-8 sm:w-6 sm:h-6 block cursor-pointer"
            ></icon>
            <span
                v-if="notificationsCount"
                class="notifications__counter right-0 -top-1 sm:-top-1 sm:-right-1"
            >
                {{ notificationsCount }}
            </span>
        </div>
        <SlideOver
            v-if="isOpen"
            title="notifications"
            @close="isOpen = false"
        >
            <ContentArea
                content-class="px-6"
                :content-loading="loading"
                watch-for-scroll
                @reachedBottom="loadMore"
            >
                <template v-slot:default>
                    <div class="pb-2">
                        <p class="py-6 sticky top-0 bg-gray-700 text-gray-200 text-lg tracking-wider uppercase font-ffdin">
                            {{ $t('notifications.title') }}
                        </p>
                        <ul>
                            <li
                                v-for="(notification, index) in formatedNotifications"
                                :key="index"
                                class="border-b border-gray-700 cursor-pointer py-4"
                            >
                                <div class="flex justify-between gap-2 items-start">
                                    <div class="text-white text-md font-ffdin tracking-normal">
                                        {{ $t('notifications.item.title') }}: {{ notification.uiTemplate.title }}
                                    </div>
                                    <icon
                                        name="close"
                                        class="h-5 w-5 cursor-pointer flex-shrink-0"
                                        @click="markNotificationAsRead(notification.id)"
                                    />
                                </div>
                                <div class="text-gray-450 text-sm leading-6 font-sofia">
                                    {{ notificationDateFormatter(notification.timestamp) }}
                                </div>
                                <p class="mt-4 text-gray-200 text-sm leading-6">
                                    {{ $t('notifications.item.description') }}:
                                    {{ notification.uiTemplate.description }}
                                </p>

                                <div class="mt-5 w-full border border-solid border-gray-600"></div>
                            </li>
                        </ul>
                    </div>
                </template>
            </ContentArea>
        </SlideOver>
    </div>
</template>

<script>
import SlideOver from '@/components/root/SlideOver';
import Icon from '@/components/ui/Icon';
import ContentArea from '@/components/ui/ContentArea';
import NotifyMixin from '@/mixins/NotifyMixin';
import AuthMixin from '@/mixins/AuthMixin.js';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import moment from 'moment';
import { dateFormat } from '@/utils/Date';
import InfiniteScrollMixin from '@/mixins/InfiniteScrollMixin';

export default {
    name: 'Notifications',

    components: { ContentArea, SlideOver, Icon },

    mixins: [NotifyMixin, AuthMixin, AccountingQueryManager, InfiniteScrollMixin],

    data: () => {
        return {
            isOpen: false,
            notificationsCount: 0,
        };
    },

    computed: {
        formatedNotifications() {
            return this.items.map(notification => {
                const res = {
                    ...notification,
                    uiTemplate: {
                        title: '',
                        description: '',
                    },
                };

                try {
                    res.uiTemplate = JSON.parse(notification.uiTemplate);
                } catch (err) {
                    res.uiTemplate.title = notification.uiTemplate;
                }

                return res;
            });
        },
    },

    watch: {
        currentProfile: {
            async handler(val) {
                if (val) {
                    this.items = [];
                    this.notificationsCount = 0;

                    await this.checkUnpaidCharges();
                    await this.loadInitial();
                }
            },
        },
    },

    async mounted() {
        if (this.currentProfile) {
            await this.checkUnpaidCharges();
        }
    },

    methods: {
        moment,

        notificationDateFormatter(value) {
            return value ? moment(value).format(`${dateFormat} @HH:mm`) : '';
        },

        toggleSlideOver(value = !this.isOpen) {
            this.isOpen = value;
        },

        async loadItems({ limit } = {}) {
            try {
                const { notifications, count } = await this.$notificationsDataProvider.getList('notifications', {
                    isRead: false,
                    pageSize: limit,
                    lastIndex: this.items[this.items.length - 1]?.id,
                });

                this.notificationsCount = count;
                return { items: notifications };
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async markNotificationAsRead(id) {
            try {
                await this.$notificationsDataProvider.update('notifications', {
                    data: {
                        notifications: [
                            {
                                id,
                                read: true,
                            },
                        ],
                    },
                });

                this.items = this.items.filter(({ id: notificationId }) => id !== notificationId);
                this.notificationsCount -= 1;
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async checkUnpaidCharges() {
            try {
                await this.queryHandler('checkUnpaidCharges', 'resident', {
                    accountId: this.currentProfile?.occupancyId,
                });
            } catch (error) {
                this.notifyError(error.message);
            }
        },
    },
};
</script>

<style scoped>
.notifications__counter {
    @apply absolute w-4 h-4 flex justify-center items-center rounded-full cursor-pointer;
    @apply bg-red-600 text-white;
    @apply font-ffdin font-normal text-xs leading-none;
}
</style>
